import React, { useState, useContext, useEffect,useCallback } from 'react';
import { Link,useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, FormControlLabel, Checkbox, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ShopContext } from '../../Context/ShopContext';
import ship from '../Assets/ship';
import './Checkoutbox.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Checkoutbox = () => {
  const [expanded, setExpanded] = useState('shipping');
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [phone, setPhone] = useState(''); // State pour le téléphone
  const [acceptedCGV, setAcceptedCGV] = useState(false); // State pour l'acceptation des CGV
  const { cartItems, tempOrder, updateTempOrder, userDetails } = useContext(ShopContext);
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en';
  const { t } = useTranslation();
  
    // État local pour stocker les informations des produits récupérées du backend
    const [productData, setProductData] = useState({});

        // Fonction pour récupérer les détails des produits du backend
        const fetchProductDetails = useCallback(async (idstore) => {
          try {
              const response = await fetch(`${backendUrl}/product/${idstore}`);
              const data = await response.json();
              if (data.success) {
                  setProductData(prevState => ({
                      ...prevState,
                      [idstore]: data.product
                  }));
              } else {
                  console.error(`Product with idstore ${idstore} not found`);
              }
          } catch (error) {
              console.error(`Error fetching product with idstore ${idstore}:`, error);
          }
      }, []); 

      useEffect(() => {
        Object.keys(cartItems).forEach(idstore => {
          if (cartItems[idstore] > 0) {
            fetchProductDetails(idstore);
          }
        });
      }, [cartItems, fetchProductDetails]); // Assurez-vous d'inclure fetchProductDetails
      
    // Calculer le total de tous les produits dans le panier
    const totalCartAmount = Object.keys(cartItems).reduce((total, idstore) => {
      const product = productData[idstore];
      const quantity = cartItems[idstore];
      if (product && quantity > 0) {
          return total + (product.pricettc * quantity);
      }
      return total;
  }, 0);

  useEffect(() => {
    updateTempOrder({ cartItems });
  }, [cartItems, updateTempOrder]);

  useEffect(() => {
    if (userDetails?.phone) {
      setPhone(userDetails.phone);
    }
  }, [userDetails]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleShippingSubmit = (address) => {
    const shippingCost = address.country === 'France' ? 0 : 10;
    const orderTotal = totalCartAmount + shippingCost;
    updateTempOrder({
      shippingAddress: address,
      shippingCost,
      orderTotal
    });
    setExpanded('billing');
  };

  const handleBillingSubmit = (address) => {
    updateTempOrder({ billingAddress: address });
    setExpanded('phone');
  };

  const handlePhoneSubmit = () => {
    // Vérifier si le numéro de téléphone est bien défini
    if (phone) {
        updateTempOrder({ phone });
        // Attendre que l'état soit bien mis à jour avant de passer au panneau suivant
        setTimeout(() => {
            setExpanded('payment');
        }, 100); // Petit délai pour assurer que l'état est bien mis à jour avant de changer le panneau
    } else {
        // Si le téléphone est vide, afficher un message d'erreur
        alert(t('phone_required'));
    }
};
  const handlePaymentSubmit = () => {
    if (acceptedCGV) {
      /*console.log('Temporary Order:', tempOrder);*/
      navigate(`/${currentLang}/checkoutpayment`); // Programmatically navigate
    } else {
      alert(t('needcgv'));
    }
  };

  return (
    <div className='checkoutbox'>
      <Accordion expanded={expanded === 'shipping'} onChange={handleAccordionChange('shipping')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><span className='checkout-title'>{t('address.shipping')}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ShippingAddressForm onSubmit={handleShippingSubmit} />
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'billing'} onChange={handleAccordionChange('billing')} disabled={!tempOrder.shippingAddress}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><span className='checkout-title'>{t('address.billing')}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BillingAddressForm 
            onSubmit={handleBillingSubmit} 
            sameAsShipping={billingSameAsShipping} 
            setSameAsShipping={setBillingSameAsShipping} 
            shippingAddress={tempOrder.shippingAddress}  
          />
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'phone'} onChange={handleAccordionChange('phone')} disabled={!tempOrder.billingAddress}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><span className='checkout-title'>{t('address.phonenumber')}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PhoneNumberForm phone={phone} setPhone={setPhone} onSubmit={handlePhoneSubmit} />
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'payment'} onChange={handleAccordionChange('payment')} disabled={!tempOrder.phone}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><span className='checkout-title'>{t('validateorder')}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PaymentMethodForm 
            onSubmit={handlePaymentSubmit} 
            acceptedCGV={acceptedCGV} 
            setAcceptedCGV={setAcceptedCGV} 
          />
          <OrderSummary handlePaymentSubmit={handlePaymentSubmit} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const OrderSummary = ({ handlePaymentSubmit }) => {
  const {  cartItems, tempOrder } = useContext(ShopContext);
  
  const shippingCost = tempOrder.shippingCost || 0;
  //const totalCartAmount = tempOrder.orderTotal-tempOrder.shippingCost ;
  //const orderTotal = tempOrder.orderTotal;
  const { t } = useTranslation();
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

    // État local pour stocker les informations des produits récupérées du backend
    const [productData, setProductData] = useState({});

        // Fonction pour récupérer les détails des produits du backend
        const fetchProductDetails = useCallback(async (idstore) => {
          try {
              const response = await fetch(`${backendUrl}/product/${idstore}`);
              const data = await response.json();
              if (data.success) {
                  setProductData(prevState => ({
                      ...prevState,
                      [idstore]: data.product
                  }));
              } else {
                  console.error(`Product with idstore ${idstore} not found`);
              }
          } catch (error) {
              console.error(`Error fetching product with idstore ${idstore}:`, error);
          }
      }, []); 

      useEffect(() => {
        Object.keys(cartItems).forEach(idstore => {
          if (cartItems[idstore] > 0) {
            fetchProductDetails(idstore);
          }
        });
      }, [cartItems, fetchProductDetails]); // Maintenant, c'est sûr d'ajouter fetchProductDetails

  return (
    <div className="order-summary checkoutbox-input checkout-title">
      <div className='order-summary-items'>
        
      {Object.keys(cartItems).map(idstore => {
        const quantity = cartItems[idstore];
        const product = productData[idstore];
        //console.log(product);
        if (quantity > 0 && product) {
                    return (
                  <div key={idstore}>
                <div className="cartitems-format cartitems-format-main">
                  <img src={`${frontendUrl}/${product.image1}`} alt="" className='carticon-product-icon' />
                  <p>{product.name}</p>
                  <p>{product.pricettc} €</p>
                  <p>x {cartItems[idstore]}</p>
                  <p>{product.pricettc * cartItems[idstore]} €</p>
                </div>
                <hr />
                      </div>
                  );
                }
return null;
})}
      </div>
      <div className="order-summary-total">
        <Typography className="order-summary-total">{t('cgv.section4.shippingCosts.title')} {shippingCost.toFixed(2)} €</Typography>
        <Typography className="order-summary-total">Total (Inc. tax): {tempOrder.orderTotal} €</Typography>
      </div>
        <Button onClick={handlePaymentSubmit} variant="contained">
        {t('completepay')}
        </Button>
    </div>
  );
};

const ShippingAddressForm = ({ onSubmit }) => {
  const { userDetails } = useContext(ShopContext);
  const [address, setAddress] = useState({
    firstname: userDetails?.firstname || '',
    lastname: userDetails?.lastname || '',
    street1: '',
    street2: '',
    postalcode: '',
    city: '',
    country: ''
  });
  const [errors, setErrors] = useState({});
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    const fetchShippingAddress = async () => {
      try {
        const response = await fetch(`${backendUrl}/getaddresses`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'auth-token': `${localStorage.getItem('auth-token')}`,
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ addressType: 0 }),
        });
        const data = await response.json();
        if (data && data.length > 0) {
          const shippingAddress = data[0];
          setAddress({
            firstname: userDetails?.firstname || '',
            lastname: userDetails?.lastname || '',
            street1: shippingAddress.street1 || '',
            street2: shippingAddress.street2 || '',
            postalcode: shippingAddress.postalcode || '',
            city: shippingAddress.city || '',
            country: shippingAddress.country || '',
          });
        }
      } catch (error) {
        console.error('Failed to fetch shipping address', error);
      }
    };

    fetchShippingAddress();
  }, [userDetails,backendUrl]);

  const validate = () => {
    let tempErrors = {};
    if (!address.firstname) tempErrors.firstname = t('address.firstrequire');
    if (!address.lastname) tempErrors.lastname = t('address.lastrequire');
    if (!address.street1) tempErrors.street1 = t('address.streetrequire')
    if (!address.postalcode) tempErrors.postalcode = t('address.postalrequire');
    if (!address.city) tempErrors.city = t('address.cityrequire');
    if (!address.country) tempErrors.country = t('address.countryrequire');
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const { t } = useTranslation();
  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (validate()) {
      onSubmit(address);
    }
  };

  return (
    <div className='checkoutbox-input'>
      <TextField
        label={t('firstname')}
        name="firstname"
        value={address.firstname}
        onChange={handleChange}
        error={!!errors.firstname}
        helperText={errors.firstname}
        fullWidth
      />
      <TextField
        label={t('lastname')}
        name="lastname"
        value={address.lastname}
        onChange={handleChange}
        error={!!errors.lastname}
        helperText={errors.lastname}
        fullWidth
      />
      <TextField
        label={t('address.street1')}
        name="street1"
        value={address.street1}
        onChange={handleChange}
        error={!!errors.street1}
        helperText={errors.street1}
        fullWidth
      />
      <TextField
        label={t('address.street2')}
        name="street2"
        value={address.street2}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label={t('address.postalcode')}
        name="postalcode"
        value={address.postalcode}
        onChange={handleChange}
        error={!!errors.postalcode}
        helperText={errors.postalcode}
        fullWidth
      />
      <TextField
        label={t('address.city')}
        name="city"
        value={address.city}
        onChange={handleChange}
        error={!!errors.city}
        helperText={errors.city}
        fullWidth
      />
      <FormControl fullWidth>
        <InputLabel>{t('address.country')}</InputLabel>
        <Select
          label={t('address.country')}
          name="country"
          value={address.country || ''}
          onChange={handleChange}
          error={!!errors.country}
        >
          {ship.map((country) => (
            <MenuItem key={country.id} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.country}</FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit}>{t('address.usethisaddress')}</Button>
    </div>
  );
};

const BillingAddressForm = ({ onSubmit, sameAsShipping, setSameAsShipping, shippingAddress }) => {
  const [address, setAddress] = useState({
    firstname: '',
    lastname: '',
    street1: '',
    street2: '',
    postalcode: '',
    city: '',
    country: ''
  });
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    if (sameAsShipping) {
      setAddress(shippingAddress);
    } else {
      setAddress({
        firstname: '',
        lastname: '',
        street1: '',
        street2: '',
        postalcode: '',
        city: '',
        country: ''
      });
    }
  }, [sameAsShipping, shippingAddress]);

  const validate = () => {
    if (sameAsShipping) return true;
    let tempErrors = {};
    if (!address.firstname) tempErrors.firstname = t('address.firstrequire');
    if (!address.lastname) tempErrors.lastname = t('address.lastrequire');
    if (!address.street1) tempErrors.street1 = t('address.streetrequire')
    if (!address.postalcode) tempErrors.postalcode = t('address.postalrequire');
    if (!address.city) tempErrors.city = t('address.cityrequire');
    if (!address.country) tempErrors.country = t('address.countryrequire');
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (validate()) {
      onSubmit(address);
    }
  };

  return (
    <div className='checkoutbox-input'>
      <FormControlLabel 
        control={
          <Checkbox
            checked={sameAsShipping}
            onChange={(e) => setSameAsShipping(e.target.checked)}
            sx={{
              color: '#0b2946', // Couleur de la checkbox par défaut
              '&.Mui-checked': {
                color: '#0b2946', // Couleur quand la checkbox est cochée
              },
            }}
          />
        }
        label={t('address.same')}
      />
      {!sameAsShipping && (
        <div className='checkoutbox-input'>
          <TextField
            label={t('firstname')}
            name="firstname"
            value={address.firstname}
            onChange={handleChange}
            error={!!errors.firstname}
            helperText={errors.firstname}
          />
          <TextField
            label={t('lastname')}
            name="lastname"
            value={address.lastname}
            onChange={handleChange}
            error={!!errors.lastname}
            helperText={errors.lastname}
          />
          <TextField
            label={t('address.street1')}
            name="street1"
            value={address.street1}
            onChange={handleChange}
            error={!!errors.street1}
            helperText={errors.street1}
          />
          <TextField
            label={t('address.street2')}
            name="street2"
            value={address.street2}
            onChange={handleChange}
          />
          <TextField
            label={t('address.postalcode')}
            name="postalcode"
            value={address.postalcode}
            onChange={handleChange}
            error={!!errors.postalcode}
            helperText={errors.postalcode}
          />
          <TextField
            label={t('address.city')}
            name="city"
            value={address.city}
            onChange={handleChange}
            error={!!errors.city}
            helperText={errors.city}
          />
          <FormControl fullWidth>
            <InputLabel>{t('address.country')}</InputLabel>
            <Select
              name="country"
              value={address.country || ''}
              onChange={handleChange}
              label={t('address.country')}
              error={!!errors.country}
            >
              {ship.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.country}</FormHelperText>
          </FormControl>
        </div>
      )}
      <Button onClick={handleSubmit}>{t('address.usethisaddress')}</Button>
    </div>
  );
};


const PhoneNumberForm = ({ phone, setPhone, onSubmit }) => {
  const [error, setError] = useState(''); // State pour gérer l'erreur
  const { t } = useTranslation();

  const handleChange = (e) => {
    setPhone(e.target.value); // Directement mettre à jour l'état phone
    if (error) {
      setError(''); // Efface l'erreur dès que l'utilisateur modifie le champ
    }
  };

  const handleSubmit = () => {
    if (!phone) {
      setError(t('phone_required')); // Message d'erreur si le champ est vide
      return;
    }
    onSubmit(); // Appelle bien la fonction onSubmit
  };

  return (
    <div className='checkoutbox-input'>
      <TextField
        label={t('address.phonenumber')} // Ajoute une étiquette explicite pour le champ
        value={phone} // Utilise phone directement
        onChange={handleChange}
        fullWidth
        error={!!error} // Déclenche l'état d'erreur dans le champ
        helperText={error} // Affiche le message d'erreur
      />
      <Button onClick={handleSubmit}>{t('usephone')}</Button>
    </div>
  );
};




const PaymentMethodForm = ({ onSubmit, acceptedCGV, setAcceptedCGV }) => {
  const [paymentMethod, setPaymentMethod] = useState('Credit Card');
  const [errors] = useState({});
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en';
  const { t } = useTranslation();
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCGVChange = (e) => {
    setAcceptedCGV(e.target.checked);
  };

  return (
    <div className='checkoutbox-input'>
      <FormControl>
        <InputLabel>{t('paymentmethod')}</InputLabel>
        <Select
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          label={t('paymentmethod')}
          error={!!errors.paymentMethod}
        >
          <MenuItem value="Credit Card">{t('creditcard')}</MenuItem>
          <MenuItem value="PayPal">PayPal</MenuItem>
        </Select>
        <FormHelperText>{errors.paymentMethod}</FormHelperText>
      </FormControl>
      <div>
      <FormControlLabel 
        control={
          <Checkbox
            checked={acceptedCGV}
            onChange={handleCGVChange}
            sx={{
              color: '#0b2946', // Couleur de la checkbox par défaut
              '&.Mui-checked': {
                color: '#0b2946', // Couleur quand la checkbox est cochée
              },
            }}
          />
        }
        label={t('acceptcgv')}
      />
      <Link to={`/${currentLang}/cgv`} target="_blank" rel="noopener noreferrer">{t('readcgv')}</Link></div>
    </div>
  );
};

export default Checkoutbox;
